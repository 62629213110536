import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%"  viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd"  transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">


<path d="M2560 3715 c0 -8 -5 -23 -10 -33 -6 -9 -23 -56 -39 -105 -27 -84 -27
-88 -9 -104 10 -9 29 -50 42 -92 14 -42 44 -130 67 -196 115 -329 364 -1068
381 -1130 12 -45 18 -95 16 -142 -2 -71 -3 -72 -32 -79 -33 -7 -69 -44 -57
-57 17 -17 461 -3 491 15 9 5 -23 7 -85 3 -55 -3 -154 -6 -220 -7 -106 -3
-120 -1 -123 14 -2 11 5 19 21 23 14 4 36 18 49 32 20 22 23 34 22 101 -1 82
-4 95 -99 372 -32 91 -88 255 -125 365 -215 633 -271 789 -287 800 -16 12 -15
17 5 88 25 85 27 116 6 72 l-15 -30 6 38 c3 21 9 45 12 53 3 8 0 14 -6 14 -6
0 -11 -7 -11 -15z"/>
<path d="M2290 2843 c-42 -116 -38 -167 20 -289 23 -48 46 -113 50 -143 5 -31
29 -109 53 -173 25 -64 51 -143 57 -174 22 -107 -7 -269 -66 -370 -29 -49
-126 -144 -172 -168 -17 -9 -55 -23 -84 -30 -36 -10 -44 -15 -26 -15 48 -2
148 37 201 77 132 99 217 272 217 440 0 97 -8 127 -113 402 -113 297 -116 309
-95 408 8 42 14 79 13 80 -2 2 -11 -9 -19 -25 l-16 -28 6 30 c3 17 8 38 11 48
2 9 2 17 0 17 -3 0 -19 -39 -37 -87z"/>
<path d="M1948 2593 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1845 2560 c-33 -17 -78 -46 -99 -65 -22 -19 -44 -35 -49 -35 -6 0
-8 -2 -5 -5 3 -3 -12 -24 -32 -46 -151 -166 -186 -513 -74 -730 16 -30 55 -81
89 -115 104 -104 202 -145 344 -143 l86 2 -55 9 c-205 36 -341 132 -415 293
-45 98 -59 164 -59 285 1 242 117 460 294 548 35 18 57 32 49 32 -8 -1 -41
-14 -74 -30z"/>
<path d="M1995 2550 c-15 -6 -6 -9 32 -9 28 -1 55 -6 58 -11 4 -6 -1 -33 -10
-60 -13 -39 -14 -57 -5 -89 17 -63 78 -101 138 -86 l27 7 -32 13 c-63 27 -87
100 -62 189 8 31 8 41 -3 47 -17 11 -117 10 -143 -1z"/>
<path d="M1786 1904 c-28 -39 -19 -169 16 -246 40 -86 129 -136 210 -118 34 7
29 13 -25 24 -71 16 -140 119 -152 226 -17 158 -17 157 -49 114z"/>
<path d="M2116 1689 c-10 -17 -16 -33 -13 -35 3 -3 -1 -30 -8 -60 -13 -55 -12
-56 9 -50 11 3 26 6 33 6 9 0 9 8 1 31 -6 17 -8 54 -4 85 3 30 5 54 3 54 -1 0
-11 -14 -21 -31z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
